@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@layer components {

    html {
        @apply h-screen p-0 flex;
    }

    body {
        @apply h-full p-0 flex-grow bg-vendor dark:bg-neutral-900;
    }

    .view {
        @apply h-screen;
    }

    .dark {
        color-scheme: dark;
    }

    button {
        @apply rounded-lg border border-primary-500 bg-primary-500 px-5 py-2.5 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300 dark:disabled:border-neutral-700 dark:disabled:bg-neutral-800 dark:disabled:text-neutral-700;
    }

    input[type=text] {
        @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 dark:bg-neutral-700 dark:text-white dark:placeholder-neutral-500 dark:border-neutral-600 dark:focus:border-neutral-700 dark:focus:ring-neutral-600;
    }

    .max-h-50vh {
        max-height: 50vh;
    }

    .max-h-80vh {
        max-height: 80vh;
    }
}