.example-enter {
    opacity: 0;
}

.example-enter-active {
    opacity: 100%;
    transition: opacity 500ms;
}

.example-exit {
    opacity: 100%;
}

.example-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}