@tailwind components;

@layer components {

    .spinner-dialog {
        @apply fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white dark:bg-neutral-900 flex flex-col items-center justify-center gap-5;
    }

    .spinner-title {
        @apply text-center text-xl font-semibold dark:text-white
    }

    .spinner-description {
        @apply w-1/3 text-center dark:text-white
    }   

}