@tailwind components;

@layer components {

    .banner {
        @apply bg-vendor flex justify-center w-full h-10 md:w-1/12 md:h-auto md:m-5 md:p-2 md:rounded;
    }

    .banner img {
        width: auto;
        height: 100%;
    }

}